import { useStorage } from '@/hooks';
import { useApiClient } from '@/hooks/useApiClient';
import { getTotalPages } from '@/lib/utils/helpers';
import { Roles } from '@/stores/useProfileStore';
import {
    Deal,
    DealFilters,
    DealListViewSkeleton,
    DealTableRow,
    GetDealListResponse,
    GetUserFilters,
    GetUserResponse,
    User,
} from '@/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';

const { getItem } = useStorage();
export const ITEMS_PER_PAGE = parseInt(getItem('ITEMS_PER_PAGE')) || 10;
const client = useApiClient();
const queryKey = ['dealList'];

export function useDealListQuery(
    filters: Partial<DealFilters>,
    select?: (data: GetDealListResponse) => DealListViewSkeleton,
    associateLicensePlate?: boolean,
) {
    return useQuery({
        queryKey,
        queryFn: async () => await client.getDeals(filters),
        select,
        refetchOnWindowFocus: false,
        retry: false,
        enabled: associateLicensePlate !== false,
        throwOnError: true,
    });
}

export const useDealListPageData = (filters: Partial<DealFilters>, associateLicensePlate?: boolean) =>
    useDealListQuery(
        filters,
        (data: GetDealListResponse) => {
            return transformDealData(data);
        },
        associateLicensePlate,
    );

export function useDealListFiltersMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (filters: DealFilters) => {
            const data = await client.getDeals({
                max: ITEMS_PER_PAGE,
                ...filters,
            });
            return data;
        },
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });

            const prevData = queryClient.getQueryData(queryKey);

            return { prevData };
        },
        onError: (err, _, context) => {
            queryClient.setQueryData(queryKey, context?.prevData);
        },
        onSettled: async (data) => {
            queryClient.setQueriesData({ queryKey }, data);
        },
    });
}

export function useDealListCsvDownloadMutation() {
    return useMutation({
        mutationFn: async (filters: DealFilters) => {
            return await client.getDealsCsv({
                max: ITEMS_PER_PAGE,
                ...filters,
            });
        },
    });
}

export function useUsersQuery(
    filters: Partial<GetUserFilters>,
    roles?: Roles | Roles[],
    valueType: 'uuid' | 'externalId' = 'uuid',
) {
    return useQuery({
        queryKey: ['userInitialList'],
        queryFn: async () => await client.getUsers({ max: 100, ...filters }),
        select: (data) => transformUserListItems(data, filters, roles, valueType),
        refetchOnWindowFocus: false,
        retry: false,
        throwOnError: true,
    });
}

export const useUserListPageData = (
    filters: Partial<GetUserFilters>,
    roles?: Roles | Roles[],
    valueType: 'uuid' | 'externalId' = 'uuid',
) => useUsersQuery(filters, roles, valueType);

export function useUserListMutation() {
    const key = ['userList'];
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (filters: Partial<GetUserFilters>) => {
            const data = await client.getUsers({
                ...filters,
                max: 100,
            });
            return data;
        },
        mutationKey: key,
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey: key });

            const prevData = queryClient.getQueryData(key);

            return { prevData };
        },
        onError: (err, _, context) => {
            queryClient.setQueryData(key, context?.prevData);
        },
        onSuccess: async (data) => {
            queryClient.setQueriesData({ queryKey: key }, data);
        },
    });
}

function transformDealData(data: GetDealListResponse) {
    return {
        items: transformDealListItems(data.items),
        pages: getTotalPages(data.totalItems, ITEMS_PER_PAGE),
        offset: data.offset,
        totalItems: data.totalItems,
    };
}

function transformDealListItems(deals: Deal[]): DealTableRow[] | [] {
    return deals.map((deal) => {
        return {
            id: deal.id,
            uuid: deal.uuid,
            opsDealId: deal.opsDealId,
            createdAt: format(new Date(deal.createdAt), 'dd/MM/yyyy HH:mm'),
            client: {
                uuid: deal.client.uuid,
                name: deal.client.name,
                lastName: deal.client.lastName,
                email: deal.client.email,
                phone: deal.client.phone,
            },
            makeName: deal.catalogueVehicle?.makeName,
            modelName: deal.catalogueVehicle?.modelName,
            tier: deal.tier,
            licensePlate: deal.vehicle?.licensePlate,
            situation: deal.situation,
            stage: deal.stage?.stage,
            lastNote: deal.lastNote
                ? {
                      content: deal.lastNote.content,
                      createdAt: deal.lastNote.createdAt,
                  }
                : undefined,
            isStarred: deal.isStarred,
            lostReason: deal.lostReason,
            salesAgent: deal.salesAgent,
            firstWonTime: deal.firstWonTime ? format(new Date(deal.firstWonTime), 'dd/MM/yyyy HH:mm') : undefined,
        };
    });
}

export function transformUserListItems(
    data: GetUserResponse,
    filters: Partial<GetUserFilters>,
    roles?: Roles | Roles[],
    valueType: 'uuid' | 'externalId' = 'uuid',
) {
    const inputValue = filters.query ?? '';

    const filterItems = (items: User[]) => {
        const filteredItems = items.filter(
            (user) =>
                user.givenName.toLowerCase().startsWith(inputValue.toLowerCase()) ||
                user.email.toLocaleLowerCase().startsWith(inputValue.toLowerCase()) ||
                user.familyName.toLowerCase().startsWith(inputValue.toLowerCase()),
        );
        return filteredItems;
    };

    const itemToOptions = (users: User[]) => {
        return users.map((user) => ({
            label: `${user.givenName} ${user.familyName}`,
            value: valueType === 'uuid' ? user.uuid : user.externalId,
        }));
    };

    if (roles) {
        const usersWithRole = data.items.filter((user) =>
            user.roles.some((role) => {
                if (Array.isArray(roles)) {
                    return roles.includes(role as Roles);
                }
                return role === roles;
            }),
        );
        const filteredItems = filterItems(usersWithRole).splice(0, 10);
        return itemToOptions(filteredItems);
    } else {
        const filteredItems = filterItems(data.items).splice(0, 10);
        return itemToOptions(filteredItems);
    }
}
