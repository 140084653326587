import { Stages } from '@/types';

export const transformStagesToObject = (stages: Stages[]): Record<string, number> => {
    return stages.reduce(
        (acc, stage) => {
            acc[stage.stage] = stage.count;
            return acc;
        },
        {} as Record<string, number>,
    );
};
