import { z } from 'zod';
import { LegalNatures } from '../Deal';

export const Client = z.object({
    uuid: z.string(),
    name: z.string(),
    lastName: z.string(),
    email: z.string().nullable(),
    phone: z.string().nullable(),
    legalNature: LegalNatures.nullable(),
    createdAt: z.string().datetime(),
    dealsCount: z.number().nullable(),
});

export const GetClientListResponse = z.object({
    max: z.number(),
    offset: z.number(),
    items: z.array(Client).default([]),
    totalItems: z.number(),
});

export const ClientFilters = z.object({
    max: z.number().optional(),
    offset: z.number().optional(),
    page: z.number().optional(),
    totalItems: z.number().optional(),
});

export const ClientFormQueryFilters = z.object({
    createdAtBetween: z.array(z.date()).optional(),
    createdAtFrom: z.string().datetime().optional(),
    createdAtTo: z.string().datetime().optional(),
    email: z.string().optional(),
    phone: z.string().optional(),
    legalNature: LegalNatures.optional(),
});

export type Client = z.infer<typeof Client>;
export type GetClientListResponse = z.infer<typeof GetClientListResponse>;
export type ClientFormQueryFilters = z.infer<typeof ClientFormQueryFilters>;
export type ClientFilters = z.infer<typeof ClientFilters> & ClientFormQueryFilters;
