import { Table } from 'flowbite-react';
import { StageC2C, stagesC2C, StagesC2CKeys } from '@/types';
import { C2CStageStatistics } from '@/views/dashboard/sections';

interface DashboardTableProps {
    readonly handleClickCard: (stage: StageC2C) => void;
    readonly title: string;
    readonly fields: C2CStageStatistics;
    readonly icon?: React.ReactNode;
}

export function DashboardTable({ handleClickCard, title, fields, icon }: DashboardTableProps) {
    return (
        <div className="w-full">
            <Table striped>
                <Table.Head>
                    <Table.HeadCell className="flex items-center gap-2 rounded-tl-lg">
                        {icon} {title.toUpperCase()}
                    </Table.HeadCell>
                    <Table.HeadCell className="rounded-tr-lg" />
                </Table.Head>
                <Table.Body>
                    {Object.keys(fields).map((stage) => (
                        <Table.Row
                            key={stage}
                            className={`${fields[stage] > 0 && 'hover:bg-blue-100 hover:cursor-pointer'}`}
                            onClick={() => {
                                if (fields[stage] > 0) handleClickCard(stage);
                            }}
                        >
                            <Table.Cell>{stagesC2C[stage as StagesC2CKeys]}</Table.Cell>
                            <Table.Cell>{fields[stage]}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
}
