import { CatalogueVehicleDetailHeader } from '@/components/Header';
import { useUuid } from '@/hooks';
import { SidebarItem } from '@/layouts';
import { useGetVehicleDetail } from '@/layouts/VehicleDetailLayout/useVehicleDetailQuery';
import { Roles, useProfileStore } from '@/stores';
import { ListGroup } from 'flowbite-react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { PropsWithChildren } from 'react';
import { MainLayout } from '../MainLayout';
import { SellerDataModule } from './SellerDataModule';

export const vehiclePagesMap = {
    info: {
        label: 'Datos vehículo',
    },
    notes: {
        label: 'Notas',
    },
    contact: {
        label: 'Datos de contacto',
    },
    gallery: {
        label: 'Galería',
    },
    documents: {
        label: 'Documentos',
    },
    repricing: {
        label: 'Registro de precios',
    },
    'status-transitions': {
        label: 'Registro de cambios',
    },
    marketing: {
        label: 'Datos marketing',
    },
    'associated-leads': {
        label: 'Leads asociados',
    },
};

export function CatalogueVehicleDetailLayout({ children }: PropsWithChildren<{}>) {
    const router = useRouter();
    const uuid = useUuid();
    const { hasRequiredRoles } = useProfileStore();

    const { data: catalogueVehicleDetails, isLoading, isError } = useGetVehicleDetail(uuid);

    const SideNavigation: SidebarItem = {
        ...vehiclePagesMap,
        contact: {
            ...vehiclePagesMap.contact,
            disabled: !hasRequiredRoles([Roles.APPRAISER, Roles.DELIVERIES, Roles.MARKETING_AGENT, Roles.PROCESSOR]),
        },
        gallery: {
            ...vehiclePagesMap.gallery,
            disabled: !hasRequiredRoles([Roles.APPRAISER, Roles.DELIVERIES, Roles.MARKETING_AGENT, Roles.PROCESSOR]),
        },
        documents: {
            ...vehiclePagesMap.documents,
            disabled: true,
        },
        repricing: {
            ...vehiclePagesMap.repricing,
            disabled: !hasRequiredRoles([Roles.APPRAISER, Roles.DELIVERIES, Roles.MARKETING_AGENT, Roles.PROCESSOR]),
        },
        'status-transitions': {
            ...vehiclePagesMap['status-transitions'],
            disabled: !hasRequiredRoles([Roles.APPRAISER, Roles.DELIVERIES, Roles.MARKETING_AGENT, Roles.PROCESSOR]),
        },
    };

    const renderHeader = () => {
        if (isLoading) {
            return (
                <div className="p-1 w-full divide-y bg-white transition-colors duration-200">
                    <div className="min-h-12 rounded animate-pulse bg-gray-300" />
                </div>
            );
        }

        if (isError || !catalogueVehicleDetails) {
            return (
                <div className="p-1 w-full divide-y bg-white">
                    <div className="min-h-12">Error al intentar obtener los datos del vehículo</div>
                </div>
            );
        }

        return <CatalogueVehicleDetailHeader catalogueVehicleDetails={catalogueVehicleDetails} />;
    };

    return (
        <MainLayout>
            {renderHeader()}

            <div className="flex items-start gap-6 p-6">
                <div className={`sticky space-y-4 'top-[149px]'}`}>
                    <SellerDataModule />
                    <ListGroup className={`min-w-[198px] divide-y p-0 sticky top-[105px]`}>
                        {Object.keys(SideNavigation)
                            .filter((item) => !SideNavigation[item].disabled)
                            .map((item) => (
                                <ListGroup.Item
                                    key={SideNavigation[item].label}
                                    className=""
                                    theme={{
                                        base: 'min-w-xl',
                                        link: {
                                            base: 'flex w-full shadow-transparent hover:rounded-lg',
                                        },
                                    }}
                                >
                                    <Link
                                        className={`flex w-full items-center justify-between p-2 ${
                                            router.asPath.startsWith(`/vehicles/${uuid}/${item}`)
                                                ? 'text-blue-500'
                                                : 'text-gray-900'
                                        }`}
                                        href={`/vehicles/${uuid}/${item}`}
                                    >
                                        <span>{SideNavigation[item].label}</span>
                                    </Link>
                                </ListGroup.Item>
                            ))}
                    </ListGroup>
                </div>

                <div className="flex flex-1 max-w-screen">{children}</div>
            </div>
        </MainLayout>
    );
}
